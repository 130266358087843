<template>
  <section class="grid grid-cols-2  w-full">
    <div
      class="flex items-center"
      v-for="(item, index) in getFlags"
      :key="index"
    >
      <template v-if="variant === 'base'">
        <vehicle-flag-item
          :text="item.text"
          :size="getSize"
          :icon="item.icon"
          :color="`purple`"
          :event-enabled="eventEnabled"
          :clickable="item.event.clickable"
          :click-event-name="item.event.clickEventName"
          v-on:[item.event.clickEventName]="
            handleEvent(item.event.clickEventName)
          "
        />
      </template>
      <template v-else-if="variant === 'list'">
        <div
          v-if="icon && item.icon"
          class="overflow-hidden mr-2 rounded-full border border-white object-fit"
          :class="getIconSize()"
        >
          <img :src="item.icon" class="object-fit" :class="getIconSize()" />
        </div>
        <span
          v-if="text"
          class="font-medium text-oLightGray"
          :class="getTextSize()"
        >
          {{ item.text }}
        </span>
      </template>
    </div>
  </section>
</template>

<script>
import VehicleFlagItem from '@/components/badge/VehicleFlagItem'

export default {
  props: {
    flags: { required: false, type: Object },
    text: { required: false, type: Boolean, default: true },
    icon: { required: false, type: Boolean, default: true },
    fallback: { required: false, type: Boolean, default: false },
    size: { required: false, type: String, default: 'sm' },
    variant: { required: false, type: String, default: 'base' },
    eventEnabled: { required: false, type: Boolean, default: false },
    task: { required: false, type: Object, default: null },
  },
  components: { VehicleFlagItem },
  data() {
    return {
      collection: {
        // operational
        hibernate_mode: {
          key: 'hibernate_mode',
          value: false,
          text: 'Sleep Mode',
          icon: require('@/assets/img/flags/sleep_mode.svg'),
          event: {
            clickable: false,
            clickEventName: 'hibernate-mode',
          },
        },
        charging_pick: {
          key: 'charging_pick',
          value: false,
          text: 'Charging Pick',
          icon: require('@/assets/img/flags/on-charging-pick.png'),
          event: {
            clickable: false,
            clickEventName: 'charging-pick',
          },
        },
        maintainance: {
          key: 'maintainance',
          value: false,
          text: 'Maintainance',
          icon: require('@/assets/img/flags/on-maintainance.png'),
          event: {
            clickable: false,
            clickEventName: 'maintainance',
          },
        },
        rebalance: {
          key: 'rebalance',
          value: false,
          text: 'Rebalance',
          icon: require('@/assets/img/flags/on-rebalance.png'),
          event: {
            clickable: false,
            clickEventName: 'rebalance',
          },
        },
        // operational

        // general
        geo_fence_alert: {
          key: 'geo_fence_alert',
          value: false,
          text: 'Out Of Geofence',
          icon: require('@/assets/img/flags/outside_of_service_area.svg'),
          event: {
            clickable: false,
            clickEventName: 'geofence-alert',
          },
        },
        restricted_alert: {
          key: 'restricted_alert',
          value: false,
          text: 'Restricted Alert',
          icon: require('@/assets/img/flags/restricted_area.svg'),
          event: {
            clickable: false,
            clickEventName: 'restricted-alert',
          },
        },
        slowzone_alert: {
          key: 'slowzone_alert',
          value: false,
          text: 'Slowzone Alert',
          icon: require('@/assets/img/flags/slow_area.svg'),
          event: {
            clickable: false,
            clickEventName: 'slowzone-alert',
          },
        },
        iot_fault: {
          key: 'iot_fault',
          value: false,
          text: 'IOT Fault',
          icon: require('@/assets/img/flags/on-iot-fault.png'),
          event: {
            clickable: false,
            clickEventName: 'iot-fault',
          },
        },
        low_battery: {
          key: 'low_battery',
          value: false,
          text: 'Low Battery',
          icon: require('@/assets/img/flags/on-low-battery.png'),
          event: {
            clickable: false,
            clickEventName: 'low-battery',
          },
        },
        missing: {
          key: 'missing',
          value: false,
          text: 'Missing',
          icon: require('@/assets/img/flags/on-missing.png'),
          event: {
            clickable: false,
            clickEventName: 'missing',
          },
        },
        // end->general

        // start->status
        is_charging: {
          key: 'charging',
          value: false,
          text: 'On Charging',
          icon: require('@/assets/img/flags/on-charging.png'),
          event: {
            clickable: false,
            clickEventName: 'charging',
          },
        },
        is_parking: {
          key: 'parking',
          value: false,
          text: 'On Parking',
          icon: require('@/assets/img/flags/on-parking.svg'),
          event: {
            clickable: false,
            clickEventName: 'parking',
          },
        },
        is_illegally_parking: {
          key: 'illegally-parking',
          value: false,
          text: 'Illegally Parked',
          icon: require('@/assets/img/flags/on-illegally-parked.svg'),
          event: {
            clickable: false,
            clickEventName: 'parking',
          },
        },
        is_reserved: {
          key: 'reservation',
          value: false,
          text: 'On Reservation',
          icon: require('@/assets/img/flags/on-reservation.png'),
          event: {
            clickable: true,
            clickEventName: 'reservation',
          },
        },
        is_on_ride: {
          key: 'on_ride',
          value: false,
          text: 'On Ride',
          icon: require('@/assets/img/flags/on-ride.png'),
          event: {
            clickable: true,
            clickEventName: 'on-ride',
          },
        },

        // end->status
        none: {
          key: 'none',
          value: true,
          text: 'No Flags',
          // TODO: Update icon (this is actually on-maintainance icon)
          icon: require('@/assets/img/flags/none.png'),
          event: {
            clickable: false,
            clickEventName: 'none',
          },
        },
      },
      clickable: {},
    }
  },
  computed: {
    getFlags() {
      const data = []
      const validKeys = Object.keys(this.collection)
      for (const [key, value] of Object.entries(this.flags)) {
        // console.log("vgf=", `${key}->${value}`);
        if (validKeys.includes(key) && value === true) {
          data.push({
            key: key,
            value: value,
            text: this.collection[key].text,
            icon: this.collection[key].icon,
            event: {
              clickable: this.collection[key].event.clickable,
              clickEventName: this.collection[key].event.clickEventName,
            },
          })
        }
      }
      if (this.fallback && data.length === 0) {
        data.push({
          key: this.collection.none.key,
          value: this.collection.none.value,
          text: this.collection.none.text,
          icon: this.collection.none.icon,
          event: {
            clickable: this.collection.none.event.clickable,
            clickEventName: this.collection.none.event.clickEventName,
          },
        })
      }
      // console.log("vgf-d=", data);
      return data
    },
    getSize() {
      let sizes = ['sm', 'base', 'lg']
      return sizes.includes(this.size) ? this.size : 'base'
    },
  },
  methods: {
    // currently only one size is available
    getIconSize() {
      return this.size === 'normal' ? 'flag-icon-nm' : 'flag-icon-lg'
    },
    getTextSize() {
      return this.size === 'normal' ? 'flag-text-nm' : 'flag-text-lg'
    },
    handleEvent(eventName) {
      // alert(eventName);
      this.$emit(eventName)
    },
  },
}
</script>

<style lang="scss" scoped>
// nm
.flag-icon-nm {
  height: 25px;
  width: 25px;
}
.flag-text-nm {
  font-size: 14px;
}
// lg
.flag-icon-sm {
  height: 37px;
  width: 37px;
}
.flag-text-sm {
  font-size: 14px;
}
</style>
